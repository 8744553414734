import { Injectable, inject } from '@angular/core';
import { PlatformService } from './platform.service';
import { Router, UrlSerializer } from '@angular/router';
import { CookieService, LocalstorageService } from 'ngx-unificator/services';
import { Location } from '@angular/common';
import { TelegramAppApiService } from './api/telegram-app-api.service';
import { UserGroupsService } from './user/user-groups.service';
import { UserService } from './user/user.service';
import { filter, first } from 'rxjs';
import {ONE_PLUS_DEPOSITOR, THREE_PACK_ALL_WELCOME_OFFERS_USED} from "./user/data/user-group.data";

enum TELEGRAM_APP_LOCALSTORAGE_KEYS {
  BT_APP_DATA = 'BT_APP_DATA',
  BT_APP_CURRENT_MISSOIN = 'BT_APP_CURRENT_MISSOIN',
  BT_APP_MISSOIN_COMPLETED = 'BT_APP_MISSOIN_COMPLETED'
}

type missionType = {
  additionalData: {
    link: string
  },
  description: string,
  enabled: boolean,
  id: number,
  name: string,
  points: number,
  type: 'REGISTRATION' | 'DEPOSIT' | 'WELCOME_COMPLETED',
}

@Injectable({
  providedIn: 'root'
})
export class TelegramAppService {
  private _platform = inject(PlatformService);
  private _serializer = inject(UrlSerializer);
  private _localStorage = inject(LocalstorageService);
  private _router = inject(Router);
  private _location = inject(Location);
  private _telegramApi = inject(TelegramAppApiService);
  private _cookie = inject(CookieService);
  private _group = inject(UserGroupsService);
  private _user = inject(UserService);


  private _currentMissionId: number = null;
  private _currentMissionData: missionType = null;
  private _btAppData: any = null;

  /**
   * Detects and handles the presence of a Telegram app in the current application.
   * This method checks the URL parameters for the presence of a 'btApp' and 'ms'
   * parameter, which indicate that the application was launched from a Telegram app.
   * If the 'btApp' and 'ms' parameters are present, it stores the mission ID and
   * BT_APP_DATA in local storage, and loads the mission data from the Telegram API.
   * If the mission has already been completed, it resets the completed mission data.
   * If the 'btApp' and 'ms' parameters are not present, it retrieves the stored
   * mission ID and BT_APP_DATA from local storage.
   * Finally, it adds the BT_APP_DATA to the custom headers for the Telegram API
   * and calls the _loadMissionData method to fetch the mission data.
   */
  public detectTelegramApp() {
    if (!this._platform.isBrowser) {
      return;
    }
    const params = this._serializer.parse(window.location.search).queryParams;
    if (params.btApp && params.ms && params.stag) {
      if (this._localStorage.get(`${TELEGRAM_APP_LOCALSTORAGE_KEYS.BT_APP_MISSOIN_COMPLETED}#${this._currentMissionId}`)) {
        this._resetCompleteMissionData();
        this._clearUrlParams(params);
        return;
      }
      this._currentMissionId = params.ms;
      this._btAppData = params.btApp;
      this._localStorage.set(TELEGRAM_APP_LOCALSTORAGE_KEYS.BT_APP_DATA, `${params.btApp}`);
      this._localStorage.set(TELEGRAM_APP_LOCALSTORAGE_KEYS.BT_APP_CURRENT_MISSOIN, `${params.ms}`);
      this._clearUrlParams(params);
    } else {
      this._btAppData = this._localStorage.get(TELEGRAM_APP_LOCALSTORAGE_KEYS.BT_APP_DATA) ?? null;
      this._currentMissionId = Number(this._localStorage.get(TELEGRAM_APP_LOCALSTORAGE_KEYS.BT_APP_CURRENT_MISSOIN)) ?? null;
    }
    this._telegramApi.customHeadersList.push({key: 'x-telegram-data', val: `${this._btAppData ?? ''}`});
    this._loadMissionData();
  }

  /**
   * Loads the mission data for the current mission ID stored in the application.
   * If no current mission ID is available, this method does nothing.
   * Otherwise, it fetches the mission data from the Telegram API service and
   * stores the response in the `_currentMissionData` property.
   */
  private _loadMissionData() {
    if (!this._currentMissionId) {
      return;
    }
    this._telegramApi.getMissionById(this._currentMissionId).subscribe((e) => {
      this._currentMissionData = e;
      this.completeMissionReducer();
    });
  }

  /**
   * Completes the current mission and updates the application state accordingly.
   * This method retrieves the user information from the `btApp` query parameter,
   * calls the `completeMission` API endpoint to mark the current mission as completed,
   * and then resets the completed mission data stored in the application.
   */
  private _completeMission() {
    if (!this._currentMissionId && this._btAppData) {
      return;
    }
    const user = JSON.parse(this._serializer.parse('?' + this._btAppData).queryParams.user) ?? {};
    this._telegramApi.completeMission({
      userId: String(user.id),
      missionId: Number(this._currentMissionId),
      type: this._currentMissionData.type
    }).subscribe((e) => {
      console.log(e);
      if (e.completed) {
        this._localStorage.set(`${TELEGRAM_APP_LOCALSTORAGE_KEYS.BT_APP_MISSOIN_COMPLETED}#${this._currentMissionId}`, `1`);
        this._resetCompleteMissionData();
      }
    });
  }

  /**
   * Resets the completed mission data stored in the application.
   * This method clears the stored BT_APP_DATA and BT_APP_CURRENT_MISSOIN
   * from local storage, and sets the _currentMissionId, _currentMissionData,
   * and _btAppData properties to null.
   */
  private _resetCompleteMissionData() {
    this._localStorage.clearItem(TELEGRAM_APP_LOCALSTORAGE_KEYS.BT_APP_DATA);
    this._localStorage.clearItem(TELEGRAM_APP_LOCALSTORAGE_KEYS.BT_APP_CURRENT_MISSOIN);
    this._currentMissionId = null;
    this._currentMissionData = null;
    this._btAppData = null;
  }

  /**
   * Clears the 'btApp' and 'ms' query parameters from the current URL and navigates to the updated URL.
   * This method is used to remove specific query parameters from the URL, likely to reset the application state.
   *
   * @param params - The current URL query parameters.
   */
  private _clearUrlParams(params) {
    setTimeout(() => {
      delete params.btApp;
      delete params.ms;
      delete params.stag;
      const tree = this._router.parseUrl(
        this._location.normalize(window.location.pathname),
      );
      tree.queryParams = params;
      this._router.navigateByUrl(tree);
    }, 100)
  }

  /**
   * Resolves the completion of various missions by checking the user's status and group membership.
   * This method is called when the user's authentication state changes, and it updates the custom headers
   * for the Telegram API before checking the completion of different missions.
   */
  public completeMissionReducer() {
    this._user.auth$.pipe(filter((auth) => !!auth), first()).subscribe(() => {
      const statusesHeaders = {
        key: 'U-GROUP',
        val: this._user.info.statuses.map(status => encodeURI(status.id)).join(','),
      };
      this._telegramApi.customHeadersList.push(statusesHeaders);
      this._telegramApi.customHeadersList.push({ key: 'UID', val: `${this._user.info.id}` });
      switch (this._currentMissionData?.type) {
        case 'REGISTRATION':
          this._resolveCompleteRegistrationMission();
          break;

        case 'DEPOSIT':
          this._resolveCompleteDepositMission();
          break;

        case 'WELCOME_COMPLETED':
          this._resolveCompleteWelcomeMission();
          break;
      }
    });
  }

  /**
   * Resolves the completion of the registration mission by checking if the user is registered.
   * If the user is registered, the mission is completed.
   */
  private _resolveCompleteRegistrationMission() {
    if (this._cookie.check('registered')) {
      this._completeMission();
    }
  }

  /**
   * Resolves the completion of the deposit mission by checking if the user is a member of the 'ONE_PLUS_DEPOSITOR' group.
   * If the user is a member of the group, the mission is completed.
   */
  private _resolveCompleteDepositMission() {
    if (this._group.isExistGroup(ONE_PLUS_DEPOSITOR)) {
      this._completeMission();
    }
  }

  /**
   * Resolves the completion of the welcome mission by checking if all welcome messages have been used.
   * If all welcome messages have been used, the mission is completed.
   */
  private _resolveCompleteWelcomeMission() {
    if (this._group.isExistGroup(THREE_PACK_ALL_WELCOME_OFFERS_USED)) {
      this._completeMission();
    }
  }

}

import { Injectable, inject } from '@angular/core';
import { ApiProvider } from './helpers/api-provider';
import { HttpHeaders } from '@angular/common/http';
import { ApiProviderFactoryService } from './helpers/api-provider-factory.service';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TelegramAppApiService {
  private _apiFactory = inject(ApiProviderFactoryService);


  private _api: ApiProvider = this._apiFactory.create({
    defaultOptions: {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
    },
    host: environment.telegramApp_api_host,
  });

  public get customHeadersList(): any[] {
    return this._api.customHeadersList;
  }

  /**
   * GET /mission/item/:id
   */
  getMissionById(id: number): Observable<any> {
    return this._api.get(`/mission/item/${id}`);
  }

  /**
   * POST /user-mission/complete
   */
  completeMission(data: { userId: string, missionId: number, type: 'REGISTRATION' | 'DEPOSIT' | 'WELCOME_COMPLETED'}): Observable<{ completed: boolean }> {
    return this._api.post(`/user-mission/complete`, data);
  }

}
